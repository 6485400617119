:root {
  /* Primary colors */
  --color-primary-50: 244, 242, 255 /* #F4F2FF */;
  --color-primary-100: 234, 232, 246 /* #EAE8F6 */;
  --color-primary-200: 213, 209, 238 /* #D5D1EE */;
  --color-primary-300: 192, 187, 229 /* #C0BBE5 */;
  --color-primary-400: 170, 163, 221 /* #AAA3DD */;
  --color-primary-500: 148, 141, 213 /* #948DD5 */;
  --color-primary-600: 128, 118, 205 /* #8076CD */;
  --color-primary-700: 108, 95, 196 /* #6C5FC4 */;
  --color-primary-900: 74, 61, 171 /* #4A3DAB */;
  --color-primary-hover: 89, 73, 189 /* #5949BD */;
  --color-primary-main: 63, 51, 153 /* #3F3399 */;

  /* Secondary colors */
  --color-secondary-main: 2, 203, 205 /* #02cbcd */;
  --color-secondary-dark: 2, 125, 125 /* #027d7d */;
  --color-secondary-light: 0, 177, 178 /* #00b1b2 */;
  --color-secondary-50: 236, 248, 248 /* #ECF8F8 */;
  --color-secondary-100: 217, 243, 243 /* #d9f3f3 */;
  --color-secondary-200: 179, 231, 232 /* #b3e7e8 */;
  --color-secondary-300: 127, 215, 216 /* #7fd7d8 */;

  /* Teal colors */
  --color-teal-50: 249, 255, 255; /* #f9ffff */
  --color-teal-100: 200, 251, 255; /* #c8fbff */
  --color-teal-200: 148, 230, 235; /* #94e6eb */
  --color-teal-300: 90, 208, 213; /* #5ad0d5 */
  --color-teal-400: 43, 194, 197; /* #2bc2c5 */
  --color-teal-500: 0, 177, 178; /* #00b1b2 */
  --color-teal-600: 0, 163, 162; /* #00a3a2 */
  --color-teal-700: 17, 148, 146; /* #119492 */
  --color-teal-800: 0, 132, 130; /* #008482 */
  --color-teal-900: 8, 102, 97; /* #086661 */

  /* Yellow colors */
  --color-yellow-50: 254, 239, 211 /* #feefd3 */;
  --color-yellow-100: 254, 234, 196 /* #feeac4 */;
  --color-yellow-200: 253, 226, 176 /* #fde2b0 */;
  --color-yellow-300: 253, 217, 150 /* #fdd996 */;
  --color-yellow-400: 252, 204, 115 /* #fccc73 */;
  --color-yellow-500: 251, 186, 69 /* #fbba45 */;
  --color-yellow-600: 250, 170, 22 /* #faaa16 */;
  --color-yellow-700: 226, 149, 5 /* #e29505 */;
  --color-yellow-800: 192, 126, 4 /* #c07e04 */;
  --color-yellow-900: 163, 107, 4 /* #a36b04 */;

  /* Red colors */
  --color-red-50: 255, 237, 233 /* #FFEDE9 */;
  --color-red-100: 255, 223, 217 /* #FFDFD9 */;
  --color-red-200: 240, 172, 168 /* #F0ACA8 */;
  --color-red-300: 220, 149, 144 /* #DC9590 */;
  --color-red-400: 237, 124, 109 /* #ED7C6D */;
  --color-red-500: 243, 111, 85 /* #F36F55 */;
  --color-red-600: 227, 103, 83 /* #E36753 */;
  --color-red-700: 209, 91, 75 /* #D15B4B */;
  --color-red-800: 197, 86, 69 /* #C55645 */;
  --color-red-900: 183, 77, 57 /* #B74D39 */;

  /* Neutral colors */
  --color-neutral-50: 249, 250, 251; /* #F9FAFB */
  --color-neutral-100: 243, 244, 246; /* #F3F4F6 */
  --color-neutral-200: 229, 231, 235; /* #E5E7EB */
  --color-neutral-300: 209, 213, 219; /* #D1D5DB */
  --color-neutral-400: 156, 163, 175; /* #9CA3AF */
  --color-neutral-500: 107, 114, 128; /* #6B7280 */
  --color-neutral-600: 75, 85, 99; /* #4B5563 */
  --color-neutral-700: 55, 65, 81; /* #374151 */
  --color-neutral-800: 31, 42, 55; /* #1F2A37 */
  --color-neutral-900: 17, 25, 40; /* #111928 */

  /* Accent colors */
  --color-accent-yellow: 251, 186, 69 /* #fbba45 */;
  --color-accent-red: 234, 91, 63 /* #ea5b3f */;
  --color-accent-green: 19, 194, 150 /* #13c296 */;
  --color-accent-blue: 0, 92, 229 /* #005ce5 */;

  /* Background color */
  --color-background-default: 255, 255, 255 /* #ffffff */;

  /* Button color */
  --color-button-color: 52, 34, 35 /* #34223B */;

  /* Common color */
  --color-white: 255, 255, 255 /* #ffffff */;
  --color-text: 17, 17, 17 /* #111111 */;
  --color-text-grey: 71, 71, 71 /* #474747 */;
  --color-dark-container-bg: 38, 38, 38 /* #262626 */;
  --color-general-bg: 246, 246, 248 /* #f6f6f8 */;
  --color-separation-line: 192, 187, 229 /* #c0bbe5 */;

  /* SHADOWS */
  --shadow-brg: 2px 2px 10px 0 rgba(0 0 0 / 0.1);
  --shadow-bg2: 0 1px 2px -1px rgba(0 0 0 / 0.1), 0 1px 3px 0 rgba(0 0 0 / 0.1);
  --shadow-bg3: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.05);

  /* BORDER RADIUS */
  --rounded-lg: 0.5rem /* 8px */;
  --rounded-xl: 0.75rem /* 12px */;
}
