@import url(https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&family=Marcellus&display=swap);


@charset "UTF-8";

@font-face {
  font-family: 'telos';
  src: url(/static/media/telos.a4c0b5dc.eot);
  src: url(/static/media/telos.a4c0b5dc.eot?#iefix) format('embedded-opentype'),
    url(/static/media/telos.5d31e6ec.woff) format('woff'),
    url(/static/media/telos.5a8488f5.ttf) format('truetype'),
    url(/static/media/telos.9a64d319.svg#telos) format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'telos' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'telos' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up:before {
  content: '\61';
}
.icon-add:before {
  content: '\62';
}
.icon-write:before {
  content: '\69';
}
.icon-undo:before {
  content: '\6a';
}
.icon-sort-down:before {
  content: '\6b';
}
.icon-send:before {
  content: '\6c';
}
.icon-search:before {
  content: '\6d';
}
.icon-refresh:before {
  content: '\6e';
}
.icon-pdf:before {
  content: '\6f';
}
.icon-options:before {
  content: '\70';
}
.icon-message:before {
  content: '\71';
}
.icon-image:before {
  content: '\72';
}
.icon-sort-up:before {
  content: '\73';
}
.icon-arrow-down:before {
  content: '\74';
}
.icon-edit:before {
  content: '\75';
}
.icon-duplicate:before {
  content: '\76';
}
.icon-close:before {
  content: '\78';
}
.icon-date:before {
  content: '\63';
}
.icon-check:before {
  content: '\64';
}
.icon-asterix:before {
  content: '\65';
}
.icon-delete:before {
  content: '\66';
}
.icon-move:before {
  content: '\67';
}
.icon-lock:before {
  content: '\68';
}
.icon-next:before {
  content: '\77';
}
.icon-previous:before {
  content: '\79';
}
.icon-hebergement:before {
  content: '\7a';
}
.icon-abonnement:before {
  content: '\41';
}
.icon-matriel:before {
  content: '\42';
}
.icon-divers:before {
  content: '\43';
}
.icon-vehicule:before {
  content: '\44';
}
.icon-transport:before {
  content: '\45';
}
.icon-repas:before {
  content: '\46';
}
.icon-indemnite-km:before {
  content: '\47';
}
.icon-achat:before {
  content: '\48';
}
.icon-services:before {
  content: '\49';
}
.icon-charges-financieres:before {
  content: '\4a';
}
.icon-salaires:before {
  content: '\4b';
}
.icon-frais:before {
  content: '\4c';
}
.icon-outils:before {
  content: '\4d';
}
.icon-infos-generales:before {
  content: '\4e';
}
.icon-utilisateurs:before {
  content: '\4f';
}
.icon-ressources:before {
  content: '\50';
}
.icon-back:before {
  content: '\51';
}
.icon-users:before {
  content: '\52';
}
.icon-progress-check:before {
  content: '\53';
}
.icon-company:before {
  content: '\54';
}
.icon-profile:before {
  content: '\55';
}
.icon-clients:before {
  content: '\56';
}
.icon-fiabilite:before {
  content: '\57';
}
.icon-confidentialite:before {
  content: '\58';
}
.icon-group-16:before {
  content: '\59';
}
.icon-rapidite:before {
  content: '\5a';
}
.icon-items:before {
  content: '\30';
}
.icon-unlock:before {
  content: '\31';
}
.icon-template:before {
  content: '\32';
}
.icon-professionalisme:before {
  content: '\33';
}
.icon-accounting:before {
  content: '\34';
}
.icon-home:before {
  content: '\35';
}
.icon-invoice:before {
  content: '\36';
}
.icon-expenses:before {
  content: '\37';
}
.icon-settings:before {
  content: '\38';
}
.icon-help:before {
  content: '\39';
}
.icon-add-client:before {
  content: '\21';
}
.icon-add-document:before {
  content: '\22';
}
.icon-add-expense:before {
  content: '\23';
}
.icon-add-item:before {
  content: '\24';
}
.icon-add-template:before {
  content: '\25';
}
.icon-add-user:before {
  content: '\26';
}
.icon-my-company:before {
  content: '\27';
}
.icon-users-permsissions:before {
  content: '\29';
}
.icon-projects:before {
  content: '\2a';
}
.icon-export:before {
  content: '\28';
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  /* Primary colors */
  --color-primary-50: 244, 242, 255 /* #F4F2FF */;
  --color-primary-100: 234, 232, 246 /* #EAE8F6 */;
  --color-primary-200: 213, 209, 238 /* #D5D1EE */;
  --color-primary-300: 192, 187, 229 /* #C0BBE5 */;
  --color-primary-400: 170, 163, 221 /* #AAA3DD */;
  --color-primary-500: 148, 141, 213 /* #948DD5 */;
  --color-primary-600: 128, 118, 205 /* #8076CD */;
  --color-primary-700: 108, 95, 196 /* #6C5FC4 */;
  --color-primary-900: 74, 61, 171 /* #4A3DAB */;
  --color-primary-hover: 89, 73, 189 /* #5949BD */;
  --color-primary-main: 63, 51, 153 /* #3F3399 */;

  /* Secondary colors */
  --color-secondary-main: 2, 203, 205 /* #02cbcd */;
  --color-secondary-dark: 2, 125, 125 /* #027d7d */;
  --color-secondary-light: 0, 177, 178 /* #00b1b2 */;
  --color-secondary-50: 236, 248, 248 /* #ECF8F8 */;
  --color-secondary-100: 217, 243, 243 /* #d9f3f3 */;
  --color-secondary-200: 179, 231, 232 /* #b3e7e8 */;
  --color-secondary-300: 127, 215, 216 /* #7fd7d8 */;

  /* Teal colors */
  --color-teal-50: 249, 255, 255; /* #f9ffff */
  --color-teal-100: 200, 251, 255; /* #c8fbff */
  --color-teal-200: 148, 230, 235; /* #94e6eb */
  --color-teal-300: 90, 208, 213; /* #5ad0d5 */
  --color-teal-400: 43, 194, 197; /* #2bc2c5 */
  --color-teal-500: 0, 177, 178; /* #00b1b2 */
  --color-teal-600: 0, 163, 162; /* #00a3a2 */
  --color-teal-700: 17, 148, 146; /* #119492 */
  --color-teal-800: 0, 132, 130; /* #008482 */
  --color-teal-900: 8, 102, 97; /* #086661 */

  /* Yellow colors */
  --color-yellow-50: 254, 239, 211 /* #feefd3 */;
  --color-yellow-100: 254, 234, 196 /* #feeac4 */;
  --color-yellow-200: 253, 226, 176 /* #fde2b0 */;
  --color-yellow-300: 253, 217, 150 /* #fdd996 */;
  --color-yellow-400: 252, 204, 115 /* #fccc73 */;
  --color-yellow-500: 251, 186, 69 /* #fbba45 */;
  --color-yellow-600: 250, 170, 22 /* #faaa16 */;
  --color-yellow-700: 226, 149, 5 /* #e29505 */;
  --color-yellow-800: 192, 126, 4 /* #c07e04 */;
  --color-yellow-900: 163, 107, 4 /* #a36b04 */;

  /* Red colors */
  --color-red-50: 255, 237, 233 /* #FFEDE9 */;
  --color-red-100: 255, 223, 217 /* #FFDFD9 */;
  --color-red-200: 240, 172, 168 /* #F0ACA8 */;
  --color-red-300: 220, 149, 144 /* #DC9590 */;
  --color-red-400: 237, 124, 109 /* #ED7C6D */;
  --color-red-500: 243, 111, 85 /* #F36F55 */;
  --color-red-600: 227, 103, 83 /* #E36753 */;
  --color-red-700: 209, 91, 75 /* #D15B4B */;
  --color-red-800: 197, 86, 69 /* #C55645 */;
  --color-red-900: 183, 77, 57 /* #B74D39 */;

  /* Neutral colors */
  --color-neutral-50: 249, 250, 251; /* #F9FAFB */
  --color-neutral-100: 243, 244, 246; /* #F3F4F6 */
  --color-neutral-200: 229, 231, 235; /* #E5E7EB */
  --color-neutral-300: 209, 213, 219; /* #D1D5DB */
  --color-neutral-400: 156, 163, 175; /* #9CA3AF */
  --color-neutral-500: 107, 114, 128; /* #6B7280 */
  --color-neutral-600: 75, 85, 99; /* #4B5563 */
  --color-neutral-700: 55, 65, 81; /* #374151 */
  --color-neutral-800: 31, 42, 55; /* #1F2A37 */
  --color-neutral-900: 17, 25, 40; /* #111928 */

  /* Accent colors */
  --color-accent-yellow: 251, 186, 69 /* #fbba45 */;
  --color-accent-red: 234, 91, 63 /* #ea5b3f */;
  --color-accent-green: 19, 194, 150 /* #13c296 */;
  --color-accent-blue: 0, 92, 229 /* #005ce5 */;

  /* Background color */
  --color-background-default: 255, 255, 255 /* #ffffff */;

  /* Button color */
  --color-button-color: 52, 34, 35 /* #34223B */;

  /* Common color */
  --color-white: 255, 255, 255 /* #ffffff */;
  --color-text: 17, 17, 17 /* #111111 */;
  --color-text-grey: 71, 71, 71 /* #474747 */;
  --color-dark-container-bg: 38, 38, 38 /* #262626 */;
  --color-general-bg: 246, 246, 248 /* #f6f6f8 */;
  --color-separation-line: 192, 187, 229 /* #c0bbe5 */;

  /* SHADOWS */
  --shadow-brg: 2px 2px 10px 0 rgba(0 0 0 / 0.1);
  --shadow-bg2: 0 1px 2px -1px rgba(0 0 0 / 0.1), 0 1px 3px 0 rgba(0 0 0 / 0.1);
  --shadow-bg3: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.05);

  /* BORDER RADIUS */
  --rounded-lg: 0.5rem /* 8px */;
  --rounded-xl: 0.75rem /* 12px */;
}

/*!

 =========================================================
 * Material Dashboard React - v1.4.1 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
/* ::-webkit-scrollbar { */
/* -webkit-appearance: none; */
/* width: 2px; */
/* } */

/* ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
} */

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover {
  color: #89229b;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  outline: 0 !important;
}

