@charset "UTF-8";

@font-face {
  font-family: 'telos';
  src: url('fonts/telos.eot');
  src: url('fonts/telos.eot?#iefix') format('embedded-opentype'),
    url('fonts/telos.woff') format('woff'),
    url('fonts/telos.ttf') format('truetype'),
    url('fonts/telos.svg#telos') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'telos' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'telos' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up:before {
  content: '\61';
}
.icon-add:before {
  content: '\62';
}
.icon-write:before {
  content: '\69';
}
.icon-undo:before {
  content: '\6a';
}
.icon-sort-down:before {
  content: '\6b';
}
.icon-send:before {
  content: '\6c';
}
.icon-search:before {
  content: '\6d';
}
.icon-refresh:before {
  content: '\6e';
}
.icon-pdf:before {
  content: '\6f';
}
.icon-options:before {
  content: '\70';
}
.icon-message:before {
  content: '\71';
}
.icon-image:before {
  content: '\72';
}
.icon-sort-up:before {
  content: '\73';
}
.icon-arrow-down:before {
  content: '\74';
}
.icon-edit:before {
  content: '\75';
}
.icon-duplicate:before {
  content: '\76';
}
.icon-close:before {
  content: '\78';
}
.icon-date:before {
  content: '\63';
}
.icon-check:before {
  content: '\64';
}
.icon-asterix:before {
  content: '\65';
}
.icon-delete:before {
  content: '\66';
}
.icon-move:before {
  content: '\67';
}
.icon-lock:before {
  content: '\68';
}
.icon-next:before {
  content: '\77';
}
.icon-previous:before {
  content: '\79';
}
.icon-hebergement:before {
  content: '\7a';
}
.icon-abonnement:before {
  content: '\41';
}
.icon-matriel:before {
  content: '\42';
}
.icon-divers:before {
  content: '\43';
}
.icon-vehicule:before {
  content: '\44';
}
.icon-transport:before {
  content: '\45';
}
.icon-repas:before {
  content: '\46';
}
.icon-indemnite-km:before {
  content: '\47';
}
.icon-achat:before {
  content: '\48';
}
.icon-services:before {
  content: '\49';
}
.icon-charges-financieres:before {
  content: '\4a';
}
.icon-salaires:before {
  content: '\4b';
}
.icon-frais:before {
  content: '\4c';
}
.icon-outils:before {
  content: '\4d';
}
.icon-infos-generales:before {
  content: '\4e';
}
.icon-utilisateurs:before {
  content: '\4f';
}
.icon-ressources:before {
  content: '\50';
}
.icon-back:before {
  content: '\51';
}
.icon-users:before {
  content: '\52';
}
.icon-progress-check:before {
  content: '\53';
}
.icon-company:before {
  content: '\54';
}
.icon-profile:before {
  content: '\55';
}
.icon-clients:before {
  content: '\56';
}
.icon-fiabilite:before {
  content: '\57';
}
.icon-confidentialite:before {
  content: '\58';
}
.icon-group-16:before {
  content: '\59';
}
.icon-rapidite:before {
  content: '\5a';
}
.icon-items:before {
  content: '\30';
}
.icon-unlock:before {
  content: '\31';
}
.icon-template:before {
  content: '\32';
}
.icon-professionalisme:before {
  content: '\33';
}
.icon-accounting:before {
  content: '\34';
}
.icon-home:before {
  content: '\35';
}
.icon-invoice:before {
  content: '\36';
}
.icon-expenses:before {
  content: '\37';
}
.icon-settings:before {
  content: '\38';
}
.icon-help:before {
  content: '\39';
}
.icon-add-client:before {
  content: '\21';
}
.icon-add-document:before {
  content: '\22';
}
.icon-add-expense:before {
  content: '\23';
}
.icon-add-item:before {
  content: '\24';
}
.icon-add-template:before {
  content: '\25';
}
.icon-add-user:before {
  content: '\26';
}
.icon-my-company:before {
  content: '\27';
}
.icon-users-permsissions:before {
  content: '\29';
}
.icon-projects:before {
  content: '\2a';
}
.icon-export:before {
  content: '\28';
}
